import React, { useState, useEffect } from "react";
import { Button, Icon, TooltipComponent } from "../../../components/Component";
import { Row, Col } from "reactstrap";
import { set, useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { Spinner, Alert } from "reactstrap";
import { ErrorMessage } from "./ErrorMessage";
import mastersServices from "../../../api/masters/mastersService";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import misc from "../../../api/misc/miscService";

interface SelectOptions {
  label: string;
  value: string;
}
const LegalInformation_Buyer: React.FC = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertInfo, setAlertInfo] = useState<{
    type: string;
    message: string;
    errors: any;
  }>({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [countdown, setCountdown] = useState<number>(10);

  const [companyAddress, setCompanyAddress] = useState<any>(null);
  const { handleSubmit, watch, setValue, getValues } = useFormContext();
  const files = watch("files");

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [stateOptions, setStateOptions] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [verifyingGstin, setVerifyingGstin] = useState(false);
  const FullFormDetails = watch();
  const gstinVerified = watch("gstinVerified");
  const gstin = watch("gstin");
  const cin = watch("cin");
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (alertInfo) {
      setCountdown(10);
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            setAlertInfo({ type: "", message: "", errors: [] });
            clearInterval(timer);
            return 10; // Reset the countdown
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [alertInfo]);

  useEffect(() => {
    if (!gstin) return;
    if (
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/.test(
        gstin
      ) ||
      /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/.test(cin) &&
      gstinVerified != "true"
    ) {
      setValue("gstinVerified", "false");
      setVerifyingGstin(true);
      validateSandbox({
        cin: cin,
        gstin: gstin,
        orgName: FullFormDetails.orgName,
      });
    } else {
      setVerifyingGstin(false);
    }
  }, [gstin, cin]);

  const validateSandbox = async (data: any) => {
    try {
      const response = await mastersServices.verifyLegalDetails({
        cin: data.cin,
        gstin: data.gstin,
        orgName: data.orgName,
      });

      const sandboxResponse = response?.verificationResponse;
      const errorsArray: string[] = [];

      // Check if any GSTIN verification failed
      const gstinVerification = sandboxResponse.find(
        (obj: any) => obj.gstin && !obj.verified
      );

      if (gstinVerification) {
        // Show alert if GSTIN verification is false
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "GSTIN verification failed.",
        });
        setVerifyingGstin(false);
        return { errors: ["GSTIN verification failed."] };
      }

      // If verification passed, return the companyAddress
      const sandBoxObj = sandboxResponse.find(
        (obj: any) => obj.gstin && obj.verified
      );

      const { companyAddress, dateOfRegistration } = sandBoxObj;

      if (!gstinVerification) {
        if (companyAddress) {
          setValue("comAddressline1", companyAddress?.bno);
          setValue("comAddressLine2", companyAddress?.landMark);
          setValue("comCountry", 1);
          await fetchStatesByCountry(
            "1",
            companyAddress?.stcd,
            companyAddress?.dst.toLowerCase().replace(/\s+/g, "")
          );

          setValue(
            "area",
            companyAddress?.locality + ", " + companyAddress?.landMark
          );

          setValue("comCity", companyAddress?.loc);
          setValue("comPincode", companyAddress?.pncd);
        }
        setValue("pan", data.gstin.substring(2, 12)); // Extract substring instead of using splice
        setValue("gstinVerified", "true");
      }
      setValue("incorporationDate", dayjs(dateOfRegistration, "DD/MM/YYYY"));
      setVerifyingGstin(false);
      return { errors: errorsArray, companyAddress };
    } catch (error: any) {
      setVerifyingGstin(false);
      console.log(error.message);
      throw error;
    }
  };

  const fetchStatesByCountry = async (
    countryId: any,
    stateName: string,
    districtName: string
  ) => {
    try {
      const data = await misc.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));

      if (stateName) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.label === stateName
        );
        if (!state) {
          return;
        }
        setValue("comState", state.value);
        await getDistricts(state?.value, districtName);
      }
    } catch (error) {}
  };
  const getDistricts = async (stateID: any, districtName: string) => {
    try {
      const response = await misc.getDistrictsByState(stateID);
      const { data, status } = response;
      if (status) {
        if (data.length > 0) {
          const district = data.find((item: any) => {
            return (
              item.districtName.toLowerCase().replace(/\s+/g, "") ==
              districtName
            );
          });
          if (district) {
            setValue("comDistrict", district.districtId.toString());
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const submitForm = async (data: any) => {
    console.log("On submit all data----", data);
    setLoading(true);
    try {
      // const onboardErrors: any = await validateOnboard(data);
      // if (onboardErrors) {
      //   return;
      // // }
      // const { errors: sandboxErrors, companyAddress } = await validateSandbox(
      //   data
      // );
      // if (sandboxErrors && sandboxErrors.length > 0) {
      //   setAlertInfo(() => ({
      //     type: "error",
      //     message: "",
      //     errors: sandboxErrors,
      //   }));
      //   setLoading(false);
      //   return;
      // }
      // // else {
      // //   setValue("pan", companyAddress.);
      // // }
      setAlertInfo({
        type: "",
        message: "",
        errors: [],
      });
      setLoading(false);
      console.log("companyAddress before next:", companyAddress);
      setValue("verificationData", companyAddress);
      props.next();
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };

  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <h5>Legal Information</h5>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="cin"
              label="CIN / LLPIN"
              // disabled={gstinVerified == "true"}
              // required={true}
              type="text"
              placeholder="Enter CIN / LLPIN"
              pattern={/^.*$/}
              // pattern={/^[a-zA-Z0-9]+$/}
              // pattern={/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="gstin"
              label="GSTIN"
              disabled={gstinVerified == "true"}
              required={true}
              type="text"
              placeholder="Enter GSTIN"
              pattern={
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
              }
            />
          </Col>
          {verifyingGstin && (
            <Col lg="4" className="d-flex align-items-end">
              <Spinner size="sm" color="primary" className="m-2" />
            </Col>
          )}
          <span style={{ display: "none" }}>
            <FormInput name="gstinVerified" label="" />
          </span>
          {gstinVerified && !verifyingGstin && (
            <>
              <Col lg="4">
                <FormInput
                  name="pan"
                  label="PAN"
                  disabled
                  required={true}
                  type="text"
                  placeholder="Enter PAN"
                  pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                />
              </Col>
              <Col lg="4">
                <FormDatePicker
                  name="incorporationDate"
                  label="Incorporation Date"
                  disabled
                  required={true}
                  // minDate={new Date()} // Today's date as the minimum date
                  maxDate={new Date()} // Maximum date set to one year from today
                />
              </Col>
            </>
          )}
        </Row>
        <Row className="gy-4 mt-1">
          <h5>Bank Information</h5>
          <Col lg="4">
            <FormInput
              name="accountName"
              label="Account Holder Name"
              required={true}
              type="text"
              placeholder="Enter Account Name"
              // minLength={10}
              // maxLength={20}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="bankaccountNumber"
              label="Account Number"
              required={true}
              type="text"
              pattern={/^\d+$/} // Allows only digits
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
              }}
              placeholder="Bank Account Number"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="bankName"
              label="Bank Name"
              required={true}
              type="text"
              placeholder="Enter Bank Name"
              // minLength={5}
              // maxLength={30}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="4">
            <FormInput
              name="branchName"
              label="Branch Name"
              required={true}
              type="text"
              placeholder="Enter Branch Name"
              // minLength={3}
              // maxLength={30}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="ifcCode"
              label="IFSC Code"
              required={true}
              type="text"
              placeholder="Enter IFSC Code"
              pattern={/^[A-Z]{4}0[A-Z0-9]{6}$/}
              maxLength={11}
            />
          </Col>
          <Col lg="4">
            {files && files[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"file"}>
                    Upload Cancelled Cheque <span className="mandatory">*</span>
                  </label>
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{files[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("files", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: `${files && files[0]?.name ? "none" : "block"}`,
              }}
            >
              <FormInput
                name="files"
                label="Upload Cancelled Cheque"
                type="file"
                required={files && files[0]?.name ? false : true}
                placeholder="Upload Cancelled Cheque"
              />
            </div>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                {loading ? <Spinner size="sm" color="light" /> : "Next"}
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default LegalInformation_Buyer;
