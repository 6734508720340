import React, { useCallback, useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Badge,
  Card,
  Row,
  Col,
  Modal,
  ModalBody,
  Button as BButton,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  UserAvatar,
  RSelect,
} from "../../components/Component";
import { Link, useParams } from "react-router-dom";
import OrganizationTypes from "./OrgTypes";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import FormSelect from "../form-components/SelectComponent";
import FormTextArea from "../form-components/TextAreaComponent";
import misc from "../../api/misc/miscService";
import FormDatePicker from "../form-components/DatePicker";
import mastersServices from "../../api/masters/mastersService";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import CropperComponent from "./buyer/CropperComponent";
import FormMultiSelect from "../form-components/MultiSelectComponent";
interface SelectOptions {
  label: string;
  value: string;
}
const PartyDetails: React.FC = () => {
  //   const {
  //     register,
  //     handleSubmit,
  //     control,
  //     setValue,
  //     reset,
  //     formState: { errors },
  //   } = useFormContext();
  const location = useLocation();
  const formMethods = useForm({
    mode: "onChange",
  });
  const [row, setRow] = useState<OrganizationTypes | any>();
  const [modal, setModal] = useState(false);
  const { handleSubmit, setValue, reset } = formMethods;
  const [orgTypeOptions, setOrgTypeOptions] = useState([]);
  const [modalEditBlockName, setModalEditBlockName] = useState("");
  const [partyType, setPartyTypeOptions] = useState([
    { label: "Buyer", value: "Buyer" },
    { label: "Seller", value: "Seller" },
  ]);

  const [categories, setCategories] = useState([]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState<
    SelectOptions[]
  >([]);

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDitrictOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const [selectedMaterials, setSelectedMaterials] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [docs, setDocs]: any = useState([]);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [signatureModal, setSignatureModal] = useState(false);
  const toggleModal = () => setSignatureModal(!signatureModal);
  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }
  const handleImageSave = async (image: string) => {
    try {
      const formData = new FormData();
      const deletedFileData: any = [];
      const imageFile = base64ToFile(image, "orgLogo.png");
      formData.append("files", imageFile);
      const previousDoc = docs?.filter(
        (document: any) => document.moduleFileType == "org logo"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
      console.log(deletedFileData, "deletedFileData");
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [
            {
              indexOfFileLocated: 0,
              module: "orgs",
              purpose: "org logo",
              level: "Header Level",
              uniqueId: "",
              action: "Create",
            },
          ],
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
      formData.append("orgData", JSON.stringify({ name: row?.name }));

      toggleModal();
      const { status, message } = await mastersServices.updateparties(
        formData,
        row.orgID
      );
      if (status) {
        Swal.fire(
          "Updated!",
          "Organisation Details has been updated",
          "success"
        );
        setModal(false);
        fetchorgByID(row?.orgID);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error: any) {
      console.log(error);

      Swal.fire("Failed!", error?.response?.data?.message, "error");
    }
  };

  const fetchSupplierCategory = async () => {
    try {
      const { data, status, message } =
        await mastersServices.getMaterialCategories();
      if (status) {
        console.log(data);
        const transformedData = data.map((obj: any) => ({
          label: obj.category,
          value: obj.id.toString(),
        }));
        setCategories(transformedData);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const fetchIndustryType = async () => {
    try {
      const { data, status, message } =
        await misc.getContructionsMaterialTypes();
      if (status) {
        console.log(data);
        const transformedData = data.map((obj: any) => ({
          label: obj.name,
          value: obj.material_id.toString(),
        }));
        setIndustryTypeOptions(transformedData);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  useEffect(() => {
    if (location.state) {
      const { row: initialRow } = location.state as { row: OrganizationTypes };
      fetchorgByID(initialRow.orgID);
      setRow(initialRow);
      // setSelectedMaterials(
      //   initialRow?.materials?.map((material: any) =>
      //     Number(material.materialID)
      //   )
      // );
      // setSelectedCategory(
      //   initialRow?.categories?.map((categorie: any) =>
      //     Number(categorie.categoryID)
      //   )
      // );
    }
  }, [location.state]);

  const fetchorgByID = async (id: any) => {
    try {
      const { data, status, message } = await mastersServices.fetchAdminOrgById(
        id
      );

      if (status) {
        const newData = data;
        console.log(newData, "newData");

        console.log(newData?.docs);

        const logo = data?.docs?.filter(
          (document: any) => document.moduleFileType == "org logo"
        );
        if (logo) {
          setCroppedImage(logo[0]?.filePath);
        }
        setDocs(newData?.docs);
        setRow(data);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  const getOrgTypes = useCallback(async () => {
    try {
      const orgTypes = await misc.getOygTypes();
      if (orgTypes.status && orgTypes.data.length > 0) {
        const transformedData = orgTypes.data.map((item: any) => ({
          value: item.id.toString(),
          label: item.orgtype,
        }));
        setOrgTypeOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);

  const onFormCancel = (e: any) => {
    e.preventDefault();
    setModal(false);
    reset();
  };
  const getCountries = async () => {
    try {
      const _countries = await misc.getCountries();
      console.log(_countries);
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountryOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const getStates = async (countryId: any) => {
    try {
      const states = await misc.getStatesByCountry(countryId);

      if (states.status) {
        if (states.data.length > 0) {
          const transformedData = states.data.map((item: any) => ({
            value: item.stateId.toString(),
            label: item.stateName,
          }));
          setStateOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const getDistricts = async (stateID: any) => {
    try {
      const districts = await misc.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDitrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const onCountryChange = (e: any) => {
    console.log(e);
    getStates(e);
  };
  const onStateChange = (e: any) => {
    getDistricts(e);
  };
  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    console.log("row", row);
    if (modalEditBlockName === "GeneralInformation") {
      getOrgTypes();
      if (row.type === "Seller") {
        fetchSupplierCategory();
        fetchIndustryType();
      }
      setValue("name", row.name);
      setValue("category", row.category?.id.toString());
      setValue("type", row.type);
      setValue(
        "materials",
        row?.materials?.map((item: any) => item.materialID.toString())
      );
      setValue(
        "categories",
        row?.categories?.map((item: any) => item.categoryID.toString())
      );
      // setValue("materials", row.materials);
      // setValue("categories", row.categories);
    }
    if (modalEditBlockName === "LegalInformation") {
      setValue("cin", row.cin);
      setValue("incorporationDate", dayjs(row.incorporationDate));
      setValue("pan", row.pan);
      setValue("gstin", row.gstin);
    }
    if (modalEditBlockName === "CompanyInformation") {
      getCountries();
      getStates(row.companyAddress.country.countryId);
      getDistricts(row.companyAddress.state.stateId);
      setValue("addressLine1", row.companyAddress.addressLine1);
      setValue("addressLine2", row.companyAddress.addressLine2);
      setValue("country", row.companyAddress.country.countryId.toString());
      setValue("state", row.companyAddress.state.stateId.toString());
      setValue("district", row.companyAddress.district.districtId.toString());
      setValue("city", row.companyAddress.city);
      setValue("contactName", row.contactName);
      setValue("adminEmail", row.adminEmail);
      setValue("phoneNumber", row.phoneNumber);
      setValue("pincode", row.companyAddress.pincode);
      setValue("websiteURL", row.websiteURL);
    }
    if (modalEditBlockName === "AdminInformation") {
    }
    // if (modalEditBlockName === "RegisteredAddressInformation") {
    //   setValue("cin", row.cin);
    //   setValue("incorporationDate", new Date(row.incorporationDate));
    //   setValue("pan", row.pan);
    //   setValue("gstin", row.gstin);
    // }
    if (modalEditBlockName === "BankAccountDetailsInformation") {
      setValue("bankaccountNumber", row.bankaccountNumber);
      setValue("accountHolderName", row.accountHolderName);
      setValue("bankName", row.bankName);
      setValue("branchName", row.branchName);
      setValue("ifscCode", row.ifscCode);
    }
    setModal(true);
  };

  const fileValidation = {
    required: "Please upload a cheque",
    validate: {
      fileFormat: (files: any) =>
        (files.length &&
          Array.from(files).every((file: any) =>
            /\.(png|jpg)$/i.test(file.name)
          )) ||
        "Only PNG and JPG files are allowed!",
    },
  };

  const submitForm = async (data: any) => {
    console.log("data----", data);
    let updatedData: any = {};

    // if (modalEditBlockName === "CompanyInformation") {
    updatedData = {
      ...data,
      companyAddress: {
        ...row.companyAddress,
        addressLine1: data.addressLine1 || row.companyAddress.addressLine1,
        addressLine2: data.addressLine2 || row.companyAddress.addressLine2,
        city: data.city || row.companyAddress.city,
        pincode: data.pincode || row.companyAddress.pincode,
        country: data.country || row.companyAddress.country,
        state: data.state || row.companyAddress.state,
        district: data.district || row.companyAddress.district,
      },
      registeredAddress: {
        ...row.registeredAddress, // Spread existing `registeredAddress`
        addressLine1: data.addressLine1 || row.registeredAddress.addressLine1, // Update addressLine1
        addressLine2: data.addressLine2 || row.registeredAddress.addressLine2, // Update addressLine2
        country: row.registeredAddress.country, // Keep existing country
        state: row.registeredAddress.state, // Keep existing state
        district: row.registeredAddress.district, // Keep existing district
        // Include other fields if necessary
      },
      categories: data.categories,
      materials: data.materials,
      // phoneNumber : data.phoneNumber
    };
    if (updatedData.companyAddress.country) {
      updatedData.companyAddress.country = parseInt(
        updatedData.companyAddress.country
      );
    }
    if (updatedData.companyAddress.state) {
      updatedData.companyAddress.state = parseInt(
        updatedData.companyAddress.state
      );
    }
    if (updatedData.companyAddress.district) {
      updatedData.companyAddress.district = parseInt(
        updatedData.companyAddress.district
      );
    }

    if (updatedData.registeredAddress.country) {
      updatedData.registeredAddress.country = parseInt(
        updatedData.registeredAddress.country
      );
    }
    if (updatedData.registeredAddress.state) {
      updatedData.registeredAddress.state = parseInt(
        updatedData.registeredAddress.state
      );
    }
    if (updatedData.registeredAddress.district) {
      updatedData.registeredAddress.district = parseInt(
        updatedData.registeredAddress.district
      );
    }

    if (updatedData?.category?.id) {
      updatedData.category = parseInt(updatedData.category.id);
    }

    delete updatedData.addressLine1;
    delete updatedData.addressLine2;
    delete updatedData.country;
    delete updatedData.district;
    delete updatedData.state;
    delete updatedData.city;
    delete updatedData.pincode;
    delete updatedData.docs;
    // delete updatedData.ifscCode;
    delete updatedData.pan;
    delete updatedData.gstin;
    delete updatedData.cin;
    // delete updatedData.phoneNumber;
    // delete updatedData.categories;
    // delete updatedData.materials;
    delete updatedData.type;

    const formData = new FormData();
    formData.append("files", JSON.stringify([]));
    formData.append(
      "filesData",
      JSON.stringify({
        newfiledata: [],
        deletedFileData: [],
        updatedFileData: [],
      })
    );
    formData.append("orgData", JSON.stringify(updatedData));
    try {
      const addAdminMaterial = await mastersServices.updateparties(
        formData,
        row.orgID
      );
      const { status, message } = addAdminMaterial;
      if (status) {
        setModal(false);
        Swal.fire("Updated!", "Organisation has been updated", "success");
        fetchorgByID(row.orgID);

        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setAlertInfo({
        type: "error",
        message: error.message,
        errors: [],
      });
    }
  };

  const handleMultiSelectChange = (selectedOptions: any, name: any) => {
    console.log("selectedOptions", selectedOptions);
    if (name == "categories") {
      const value = selectedOptions.map((option: any) => ({
        categoryID: option.value,
        categoryName: option.label,
      }));
      setValue("categories", value);
      console.log(value, "value111");
      // setFormData({ name:value });
    } else if (name == "materials") {
      const value = selectedOptions.map((option: any) => ({
        materialID: option.value,
        materialName: option.label,
      }));
      setValue("materials", value);
      console.log(value, "value2222");
    }
  };

  return (
    <>
      {row && (
        <React.Fragment>
          <Head title="Party Details"></Head>
          <Content>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Party Details /{" "}
                    <strong className="text-primary small">{row.name}</strong>
                  </BlockTitle>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Link to={`${process.env.PUBLIC_URL}/party-list`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Block>
              <Row className="gy-5">
                <Col lg="6">
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">General Information</BlockTitle>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("GeneralInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Organisation Name</div>
                          <div className="data-value">{row.name}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Registered As</div>
                          <div className="data-value">
                            <Badge
                              size="lg"
                              color={"outline-primary"}
                              pill
                              className="badge-md badge-dim"
                            >
                              {row.type}
                            </Badge>
                          </div>
                        </div>
                      </li>
                      {row.type === "Seller" && (
                        <>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Material Type</div>
                              <div className="data-value">
                                {row.materials
                                  .map((material: any) => material.materialName)
                                  .join(", ")}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Supply Category</div>
                              <div className="data-value">
                                {row.categories
                                  .map(
                                    (categories: any) => categories.categoryName
                                  )
                                  .join(", ")}
                              </div>
                            </div>
                          </li>
                        </>
                      )}
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Organisation Type</div>
                          <div className="data-value">{row.category.name}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Registered Date</div>
                          <div className="data-value">
                            {row.incorporationDate
                              ? moment(row.incorporationDate).format(
                                  "DD-MMM-YYYY"
                                )
                              : ""}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Last Modified Date</div>
                          <div className="data-value">
                            {row.modifiedDate
                              ? moment(row.modifiedDate).format("DD-MMM-YYYY")
                              : ""}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Logo</div>
                          <div className="data-value">
                            <div className="nk-data data-list">
                              {!croppedImage && (
                                <BButton
                                  color="btn-dim btn"
                                  onClick={toggleModal}
                                >
                                  <span>Upload Logo </span>
                                  <Icon name="upload-cloud" />
                                </BButton>
                              )}
                              {croppedImage && (
                                <div className="mt-3 w-100">
                                  <div
                                    style={{
                                      marginLeft: "25px",
                                      height: "60px",
                                      overflow: "hidden",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <img
                                      src={croppedImage}
                                      alt="Cropped"
                                      style={{ height: "60px" }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center mt-3">
                                    <BButton
                                      color="btn-dim btn"
                                      onClick={toggleModal}
                                    >
                                      <span>Change</span>
                                      <Icon
                                        name="upload-cloud"
                                        className="pointer"
                                        onClick={toggleModal}
                                      />
                                    </BButton>
                                    {/* <BButton
                                color="btn-dim btn"
                                onClick={() => {
                                  setCroppedImage(null);
                                }}
                              >
                                <span>Cancel</span>
                                <Icon
                                  name="cross"
                                  className="pointer"
                                  onClick={() => {
                                    setCroppedImage(null);
                                  }}
                                />
                              </BButton> */}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Legal Information</BlockTitle>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("LegalInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">CIN</div>
                          <div className="data-value">{row.cin}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">PAN</div>
                          <div className="data-value">{row.pan}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">GTIN</div>
                          <div className="data-value">{row.gstin}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Incorporation Date</div>
                          <div className="data-value">
                            {row.incorporationDate
                              ? moment(row.incorporationDate).format(
                                  "DD-MMM-YYYY"
                                )
                              : ""}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contact Information</BlockTitle>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("CompanyInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Name</div>
                          <div className="data-value">{row.contactName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email</div>
                          <div className="data-value">
                            <a href={`mailto:${row.adminEmail}`}>
                              {row.adminEmail}
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{row.phoneNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Company URL</div>
                          <div className="data-value">
                            <a href={row.websiteURL} target="_blank">
                              {row.websiteURL}
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Sub Domain</div>
                          <div className="data-value">{row.subDomain}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Country</div>
                          <div className="data-value">
                            {row.companyAddress.country.countryName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">State/Province</div>
                          <div className="data-value">
                            {row.companyAddress.state.stateName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">City/Town</div>
                          <div className="data-value">
                            {row.companyAddress.city}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">District</div>
                          <div className="data-value">
                            {row.companyAddress.district.districtName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Address Line 1</div>
                          <div className="data-value">
                            {row.companyAddress.addressLine1}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Address Line 2</div>
                          <div className="data-value">
                            {row.companyAddress.addressLine2}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Pin code/ Zip Code</div>
                          <div className="data-value">
                            {row.companyAddress.pincode}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>
                <Col lg="6">
                  {/* <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Admin Details</BlockTitle>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("AdminInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead> */}
                  {/* <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Name</div>
                          <div className="data-value">{row.contactName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email</div>
                          <div className="data-value">
                            <a href={`mailto:${row.adminEmail}`}>
                              {row.adminEmail}
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{row.phoneNumber}</div>
                        </div>
                      </li>
                    </ul>
                  </Card> */}
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Registered Address</BlockTitle>
                      </BlockHeadContent>
                      {/* <BlockHeadContent>
                    <Button
                      outline
                      className="d-none d-sm-inline-flex"
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        openEditModal("RegisteredAddressInformation")
                      }
                    >
                      <Icon name="edit-alt-fill"></Icon>
                    </Button>
                  </BlockHeadContent> */}
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Country</div>
                          <div className="data-value">
                            {row.registeredAddress.country.countryName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">State/Province</div>
                          <div className="data-value">
                            {row.registeredAddress.state.stateName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">District</div>
                          <div className="data-value">
                            {row.registeredAddress.district.districtName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">City/Town</div>
                          <div className="data-value">
                            {row.registeredAddress.city}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Address Line 1</div>
                          <div className="data-value">
                            {row.registeredAddress.addressLine1}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Address Line 2</div>
                          <div className="data-value">
                            {row.registeredAddress.addressLine2}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Pin Code/District</div>
                          <div className="data-value">
                            {row.registeredAddress.pincode}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Bank Account Details</BlockTitle>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal("BankAccountDetailsInformation")
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Account Holder Name</div>
                          <div className="data-value">
                            {row.accountHolderName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Account Number</div>
                          <div className="data-value">
                            {row.bankaccountNumber}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Bank Name</div>
                          <div className="data-value">{row.bankName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Branch Name</div>
                          <div className="data-value">{row.branchName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">IFSC Code</div>
                          <div className="data-value">{row.ifscCode}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>
            <CropperComponent
              modal={signatureModal}
              toggle={toggleModal}
              onSave={handleImageSave}
            />
            <Modal
              isOpen={modal}
              toggle={() => setModal(false)}
              className="modal-dialog-centered"
              size="xl"
              backdrop="static"
            >
              {modalEditBlockName === "GeneralInformation" && (
                <ModalBody>
                  <a href="#cancel" onClick={onFormCancel} className="close">
                    <Icon name="cross-sm"></Icon>
                  </a>
                  <div className="p-2">
                    <h5 className="title">Edit General Information</h5>
                    <div className="mt-4">
                      <FormProvider {...formMethods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col lg="4">
                              <FormInput
                                name="name"
                                label="Organisation Name"
                                disabled={true}
                                required={true}
                                type="text"
                                minLength={2}
                                maxLength={30}
                                placeholder="Enter Organisation name"
                              />
                            </Col>
                            <Col lg="4">
                              <FormSelect
                                name="category"
                                label="Organisation Type"
                                options={orgTypeOptions}
                                required={true}
                                placeholder="-Select-"
                              />
                            </Col>
                            <Col lg="4">
                              <FormSelect
                                name="type"
                                label="Party Type"
                                options={partyType}
                                required={true}
                                disabled={true}
                                placeholder="-Select-"
                              />
                            </Col>
                            {row.type === "Seller" && (
                              <>
                                <Col md="6">
                                  <div className="form-group">
                                    {/* <label
                                      className="form-label"
                                      htmlFor="materialType"
                                    >
                                      Material Type{" "}
                                    </label> */}
                                    <FormMultiSelect
                                      name="materials"
                                      options={industryTypeOptions}
                                      placeholder="Select Industry Type"
                                      label="Material Type"
                                      // onChange={(value: any) => {
                                      //   handleMultiSelectChange(
                                      //     value,
                                      //     "materials"
                                      //   );
                                      //   setSelectedMaterials(
                                      //     value.map((item: any) =>
                                      //       Number(item.value)
                                      //     )
                                      //   );
                                      // }}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  {/* <label
                                    className="form-label"
                                    htmlFor="supplierCategory"
                                  >
                                    Supply Category
                                  </label> */}
                                  <FormMultiSelect
                                    options={categories}
                                    name="categories"
                                    label="Supply Category"
                                    placeholder="Select a Supply Categories"
                                    // defaultValue={row.categories.map(
                                    //   (categories: any) => ({
                                    //     label: categories.categoryName,
                                    //     value: categories.categoryID,
                                    //   })
                                    // )}
                                    // onChange={(value: any) => {
                                    //   handleMultiSelectChange(
                                    //     value,
                                    //     "categories"
                                    //   );
                                    //   setSelectedCategory(
                                    //     value.map((item: any) =>
                                    //       Number(item.value)
                                    //     )
                                    //   );
                                    // }}
                                    // defaultValue={row.categories.map(
                                    //   (categories: any) => ({
                                    //     label: categories.categoryName,
                                    //     value: categories.categoryID,
                                    //   })
                                    // )}
                                    // onChange={(value: any) =>
                                    //   handleMultiSelectChange(
                                    //     value,
                                    //     "categories"
                                    //   )
                                    // }
                                  />
                                </Col>
                              </>
                            )}
                            <Col lg="6">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#cancel"
                                    onClick={onFormCancel}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "LegalInformation" && (
                <ModalBody>
                  <a href="#cancel" onClick={onFormCancel} className="close">
                    <Icon name="cross-sm"></Icon>
                  </a>
                  <div className="p-2">
                    <h5 className="title">Edit Legal Information</h5>
                    <div className="mt-4">
                      <FormProvider {...formMethods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col lg="6">
                              <FormInput
                                name="cin"
                                label="CIN / LLPIN"
                                // required={true}
                                type="text"
                                minLength={2}
                                maxLength={30}
                                placeholder="Enter CIN / LLPIN"
                                disabled={true}
                                pattern={/^.*$/}
                              />
                            </Col>
                            <Col lg="6">
                              <FormDatePicker
                                name="incorporationDate"
                                label="Incorporation Date"
                                disabled={true}
                                // disabled={true}
                                maxDate={new Date()} // Today's date as the minimum date

                                //maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} // Maximum date set to one year from today
                                // required={true}
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="pan"
                                label="PAN"
                                required={true}
                                type="text"
                                placeholder="Enter PAN"
                                disabled={true}
                                pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="gstin"
                                label="GSTIN"
                                required={true}
                                type="text"
                                placeholder="Enter GSTIN"
                                disabled={true}
                                pattern={
                                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                                }
                              />
                            </Col>
                            <Col lg="6">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#cancel"
                                    onClick={onFormCancel}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "CompanyInformation" && (
                <ModalBody>
                  <a href="#cancel" onClick={onFormCancel} className="close">
                    <Icon name="cross-sm"></Icon>
                  </a>
                  <div className="p-2">
                    <h5 className="title">Edit Contact Information</h5>
                    <div className="mt-4">
                      <FormProvider {...formMethods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col lg="4">
                              <FormInput
                                name="contactName"
                                label="Name (Org Admin)"
                                required={false}
                                type="text"
                                placeholder="Enter First Name"
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="adminEmail"
                                label="Admin Email"
                                required={true}
                                disabled={true}
                                type="email"
                                placeholder="Enter Email address"
                                pattern={
                                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/
                                }
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="phoneNumber"
                                label="Phone Number"
                                placeholder="Enter Admin Phone Number"
                                type="text"
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="websiteURL"
                                label="Company URL"
                                required={false}
                                type="url"
                                placeholder="Enter website URL"
                              />
                            </Col>
                            <Col lg="4">
                              <FormSelect
                                name="country"
                                label="Country"
                                disabled={true}
                                options={countryOptions}
                                required={true}
                                placeholder="-Select-"
                                onChange={(e) => {
                                  onCountryChange(e);
                                }}
                              />
                            </Col>
                            <Col lg="4">
                              <FormSelect
                                name="state"
                                label="State"
                                options={stateOptions}
                                disabled={true}
                                required={true}
                                placeholder="-Select-"
                                onChange={(e) => onStateChange(e)}
                              />
                            </Col>
                            <Col lg="4">
                              <FormSelect
                                name="district"
                                label="District"
                                options={districtOptions}
                                disabled={true}
                                required={true}
                                placeholder="-Select-"
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="city"
                                label="City/Town"
                                required={true}
                                disabled={true}
                                type="text"
                                placeholder="Enter City/Town"
                                minLength={3}
                                maxLength={30}
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="pincode"
                                label="Zip/Pincode"
                                required={true}
                                disabled={true}
                                type="text"
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                                placeholder="Enter Zip or pin code"
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="addressLine1"
                                label="Address Line 1"
                                required={true}
                                disabled={true}
                                type="text"
                                placeholder="Street Address Line 1"
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="addressLine2"
                                label="Address Line 2"
                                required={true}
                                disabled={true}
                                type="text"
                                placeholder="Street Address Line 2"
                              />
                            </Col>
                            <Col lg="6">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#cancel"
                                    onClick={onFormCancel}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "AdminInformation" && (
                <ModalBody>
                  <a href="#cancel" onClick={onFormCancel} className="close">
                    <Icon name="cross-sm"></Icon>
                  </a>
                  <div className="p-2">
                    <h5 className="title">Edit Admin Details</h5>
                    <div className="mt-4">
                      <FormProvider {...formMethods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col lg="6">
                              <FormInput
                                name="contactName"
                                label="Name (Org Admin)"
                                required={false}
                                type="text"
                                placeholder="Enter First Name"
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="adminEmail"
                                label="Admin Email"
                                required={true}
                                type="email"
                                placeholder="Enter Email address"
                                pattern={
                                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/
                                }
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="phoneNumber"
                                label="Phone Number"
                                placeholder="Enter Admin Phone Number"
                                type="text"
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#cancel"
                                    onClick={onFormCancel}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {/* {modalEditBlockName === "RegisteredAddressInformation" && (
            <ModalBody>
              <a href="#cancel" onClick={onFormCancel} className="close">
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">Edit Company/Business Address</h5>
                <div className="mt-4">
                  <FormProvider {...formMethods}>
                    <form
                      className="content clearfix"
                      onSubmit={handleSubmit(submitForm)}
                    >
                      <Row className="gy-4">
                        <Col lg="6">
                          <FormSelect
                            name="RegisteredCountry"
                            label="Registered Country"
                            options={[]}
                            required={true}
                            placeholder="-Select-"
                          />
                        </Col>
                        <Col lg="6">
                          <FormSelect
                            name="RegisteredState"
                            label="Registered State"
                            options={[]}
                            required={true}
                            placeholder="-Select-"
                          />
                        </Col>
                        <Col lg="6">
                          <FormSelect
                            name="RegisteredDistrict"
                            label="Registered District"
                            options={[]}
                            required={true}
                            placeholder="-Select-"
                          />
                        </Col>
                        <Col lg="6">
                          <FormInput
                            name="RegisteredCity"
                            label="Registered City/Town"
                            required={true}
                            type="text"
                            placeholder="Enter City/Town"
                          />
                        </Col>
                        <Col lg="6">
                          <FormInput
                            name="Registered Pincode"
                            label="Registered Zip/Pincode"
                            required={true}
                            type="text"
                            placeholder="Enter Billing Zip or pin code"
                          />
                        </Col>
                        <Col lg="6">
                          <FormInput
                            name="registeredAddressLine1"
                            label="Registered Address Line 1"
                            required={true}
                            type="text"
                            placeholder="Registered Street Address Line 1"
                          />
                        </Col>
                        <Col lg="6">
                          <FormInput
                            name="RegisteredAddressLine2"
                            label="Registered Address Line 2"
                            required={true}
                            type="text"
                            placeholder="Registered Street Address Line 2"
                          />
                        </Col>
                        <Col lg="6">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button color="primary" size="md" type="submit">
                                Submit
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#cancel"
                                onClick={onFormCancel}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </ModalBody>
          )} */}

              {modalEditBlockName === "BankAccountDetailsInformation" && (
                <ModalBody>
                  <a href="#cancel" onClick={onFormCancel} className="close">
                    <Icon name="cross-sm"></Icon>
                  </a>
                  <div className="p-2">
                    <h5 className="title">Edit Bank Account Details</h5>
                    <div className="mt-4">
                      <FormProvider {...formMethods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col lg="6">
                              <FormInput
                                name="accountHolderName"
                                label="Account Holder Name"
                                required={true}
                                type="text"
                                placeholder="Enter Account Name"
                                // minLength={10}
                                // maxLength={20}
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="bankaccountNumber"
                                label="Account Number"
                                required={true}
                                type="number"
                                placeholder="Bank Account Number"
                                // minLength={10}
                                // maxLength={20}
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="bankName"
                                label="Bank Name"
                                required={true}
                                type="text"
                                placeholder="Enter Bank Name"
                                // minLength={5}
                                // maxLength={30}
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="branchName"
                                label="Branch Name"
                                required={true}
                                type="text"
                                placeholder="Enter Branch Name"
                                // minLength={3}
                                // maxLength={30}
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="ifscCode"
                                label="IFSC Code"
                                required={true}
                                type="text"
                                placeholder="Enter IFSC Code"
                                // minLength={3}
                                pattern={/^[A-Z]{4}0[A-Z0-9]{6}$/}
                                maxLength={11}
                              />
                            </Col>
                            {/* <Col lg="6">
                          <div className="form-group">
                            <label className="form-label">Upload Cheque</label>
                            <div className="form-control-wrap">
                              <Controller
                                control={control}
                                name="files"
                                rules={fileValidation}
                                render={({ field, fieldState }) => (
                                  <div className="form-file">
                                    <input
                                      type="file"
                                      onChange={(e) =>
                                        field.onChange(e.target.files)
                                      }
                                      ref={field.ref}
                                    />
                                    {fieldState.error && (
                                      <div
                                        className="mandatory"
                                        style={{ display: "block" }}
                                      >
                                        {fieldState.error.message}
                                      </div>
                                    )}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </Col> */}

                            <Col lg="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#cancel"
                                    onClick={onFormCancel}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
            </Modal>
          </Content>
        </React.Fragment>
      )}
    </>
  );
};

export default PartyDetails;
